const DEFAULT = {
  headers: {
    'Content-Type': 'application/json'
  }
}

function _getHeaders (headers, requireDefault = true) {
  if (!requireDefault) {
    return { ...headers }
  }
  return {
    ...DEFAULT.headers,
    ...headers
  }
}

function _responseAdapter (res) {
  const contentType = res.headers.get('content-type') || ''

  if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || contentType.includes('text/csv')) {
    return res.blob().then((blob) => {
      const newRes = { ...res, data: blob, headers: {} }
      res.headers.forEach((value, key) => {
        newRes.headers[key] = value
      })
      if (!res.ok) {
        throw new Error('Request failed')
      }
      return newRes
    })
  }

  // default json response handler
  return res.json().then((body) => {
    const newRes = { ...res, body, headers: {} }
    res.headers.forEach((value, key) => {
      newRes.headers[key] = value
    })
    if (!res.ok) {
      newRes.body.responseHeaders = newRes.headers
      throw newRes.body || newRes
    }
    return newRes
  })
}

function _requestAdapter (body) {
  if (body instanceof FormData) {
    return body // Return FormData as is
  }
  return JSON.stringify(body)
}

const _emptyValues = [undefined, null, '']

function _hasValue (value) {
  if (_emptyValues.includes(value)) {
    return false
  }
  if (Array.isArray(value)) {
    return value.filter(i => !_emptyValues.includes(i))
      .length
  }
  return true
}

function buildUrl ({
  url,
  queryParams
}) {
  const isFullUrl = url.indexOf('http') === 0
  const urlObj = new URL((isFullUrl ? '' : document.location.origin) + url)

  const searchParams = urlObj.searchParams
  Object.entries(queryParams || {})
    .filter(([_, value]) => _hasValue(value))
    .forEach(([key, value]) => {
      const values = Array.isArray(value) ? value : [value]
      values.forEach(i => searchParams.append(key, i))
    })

  return urlObj
}

export function getDataViaApi ({
  url,
  queryParams,
  headers,
  requireHeaders = false
}) {
  const urlObj = buildUrl({
    url,
    queryParams
  })
  return fetch(urlObj, {
    method: 'GET',
    headers: _getHeaders(headers, requireHeaders)
  }).then(_responseAdapter)
}

export function postDataViaApi ({
  url,
  queryParams,
  headers,
  body,
  requireHeaders = true
}) {
  const urlObj = buildUrl({
    url,
    queryParams
  })
  return fetch(urlObj, {
    method: 'POST',
    headers: _getHeaders(headers, requireHeaders),
    body: _requestAdapter(body)
  }).then(_responseAdapter)
}

export function putDataViaApi ({
  url,
  queryParams,
  headers,
  body,
  requireHeaders = true
}) {
  const urlObj = buildUrl({
    url,
    queryParams
  })
  return fetch(urlObj, {
    method: 'PUT',
    headers: _getHeaders(headers, requireHeaders),
    body: _requestAdapter(body)
  }).then(_responseAdapter)
}

export function deleteDataViaApi ({
  url,
  queryParams,
  headers,
  requireHeaders = true
}) {
  const urlObj = buildUrl({
    url,
    queryParams
  })
  return fetch(urlObj, {
    method: 'DELETE',
    headers: _getHeaders(headers, requireHeaders)
  }).then(_responseAdapter)
}

export function requestDataViaExternalApi ({
  url,
  method,
  queryParams,
  headers,
  body
}) {
  const urlObj = buildUrl({
    url,
    queryParams
  })
  return fetch(urlObj, {
    method,
    headers: _getHeaders(headers),
    body: _requestAdapter(body)
  }).then(_responseAdapter)
}

export default {
  getDataViaApi,
  postDataViaApi,
  putDataViaApi,
  deleteDataViaApi,
  requestDataViaExternalApi
}
