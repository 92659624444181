import table from '@/config/table'
import purchaseOrder from '@/config/purchase-order'
import installments from '@/config/installments'
import storePreferences from '@/config/store-preferences'
import role from '@/config/role'
import { capitalize } from 'vue'

const isApp = import.meta.env.VITE_IS_APP === 'true'
const baseRoute = '/'
const baseAdminRoute = baseRoute + 'admin/'
const baseApi = isApp ? import.meta.env.VITE_API_URL : ''

const app = {
  routerName: {
    home: 'Halaman Utama',
    search: 'Cari',
    productList: 'Cari Produk',
    productDetail: 'Product Detail',
    purchaseOrder: 'Daftar Pemesanan',
    stockIn: 'Lihat Barang Masuk',
    storeTransaction: 'Setoran Harian',
    internal: {
      home: 'Dashboard',
      priceAdjustment: 'Penyesuaian Harga',
      stockSummary: 'Stock summary',
      purchaseOrder: 'Pemesanan Supplier',
      storeOrder: 'Distribusi Toko',
      report: 'Laporan'
    },
    thirdParty: {
      acInstallation: 'Pasang AC'
    },
    login: 'Login',
    notAuthorized: 'Not Authorized',
    notFound: 'Not Found'
  },
  quickFilter: {
    sortOptions: [
      {
        value: 'createdDate,DESC',
        label: 'Terbaru'
      },
      {
        value: 'name,ASC',
        label: 'Nama (A-Z)'
      },
      {
        value: 'name,DESC',
        label: 'Nama (Z-A)'
      },
      {
        value: 'sellPrice,ASC',
        label: 'Harga Terendah'
      },
      {
        value: 'sellPrice,DESC',
        label: 'Harga Tertinggi'
      },
      {
        value: 'totalStockWithVariant,ASC',
        label: 'Stok Paling Sedikit'
      },
      {
        value: 'totalStockWithVariant,DESC',
        label: 'Stok Paling Banyak'
      }
    ]
  },
  getAppTitle: (routerName) => `${routerName} | ${capitalize(window.location.hostname || 'Toko Elektronik Modern')}`,
  languages: [
    {
      name: 'English',
      code: 'en'
    },
    {
      name: 'Bahasa Indonesia',
      code: 'id'
    }
  ],
  defaultPageStart: 0,
  shorterPageSize: 10,
  defaultPageSize: 20,
  longerPageSize: 100,
  numberPattern: '-?[0-9]+',
  shorterDebounceWait: 50,
  defaultDebounceWait: 200,
  longerDebounceWait: 500,
  longestDebounceWait: 1000,
  defaultInterval: 20000,
  longerInterval: 60000,
  syncTimeMillisThreshold: 3600000,
  whatsAppLink: (number) => `https://wa.me/+${number.trim()}`,
  formModal: {
    formItemLayout: {
      labelCol: {
        sm: {
          span: 10
        }
      },
      wrapperCol: {
        sm: {
          span: 14
        }
      }
    }
  },
  localStorage: {
    language: 'lang',
    purchaseOrders: 'purchaseOrders',
    supplierNotebooks: 'supplierNotebooks',
    firstTourFinished: 'firstTourFinished',
    lastSyncTime: 'lastSyncTime',
    locationViewType: 'locationViewType'
  },
  locationViewType: {
    defaultView: 'default',
    grouped: 'grouped'
  },
  ...table,
  ...storePreferences,
  ...installments,
  ...purchaseOrder,
  ...role
}
const pages = {
  home: baseRoute,
  search: baseRoute + 'search',
  productList: baseRoute + 'search/:search',
  productDetail: baseRoute + 'product/:name/:id',
  purchaseOrder: baseRoute + 'purchase-order',
  stockIn: baseRoute + 'stock-in',
  storeTransaction: baseRoute + 'store-transaction',
  login: baseRoute + 'login',
  internal: {
    home: baseAdminRoute,
    priceAdjustment: baseAdminRoute + 'price-adjustment',
    stockSummary: baseAdminRoute + 'stock-summary',
    purchaseOrder: baseAdminRoute + 'purchase-order',
    storeOrder: baseAdminRoute + 'store-order',
    report: baseAdminRoute + 'report'
  },
  thirdParty: {
    acInstallation: baseRoute + 'ac-installation'
  },
  notAuthorized: baseRoute + 'not-authorized',
  notFound: baseRoute + ':catchAll(.*)'
}

const api = {
  // external
  user: baseApi + '/api/user/check-auth',
  checkAccount: baseApi + '/api/user/check-account',
  requestOtp: baseApi + '/api/user/request-otp',
  login: baseApi + '/api/user/login',
  logout: baseApi + '/api/user/logout',
  highlightedProducts: baseApi + '/api/external/highlighted-products',
  products: baseApi + '/api/external/products',
  syncing: baseApi + '/api/external/syncing',
  categories: baseApi + '/api/external/categories',
  domainConfigs: baseApi + '/api/external/domain-configs',
  currentDomainConfig: baseApi + '/api/external/domain-configs/current',
  productDetail: (productId) => baseApi + `/api/external/products/${productId}`,
  dashboard: baseApi + '/api/olsera/dashboard',
  // internal
  internal: {
    purchaseOrders: baseApi + '/api/purchase-orders',
    multiplePurchaseOrders: baseApi + '/api/purchase-orders/_multiple',
    productPurchaseOrders: baseApi + '/api/purchase-orders/_product',
    storePurchaseOrders: baseApi + '/api/purchase-orders/_list',
    purchaseOrderReport: baseApi + '/api/purchase-orders/_download',
    purchaseOrderShippingNote: (id) => baseApi + `/api/purchase-orders/${id}/_shipping-note`,
    purchaseOrderSummary: baseApi + '/api/purchase-orders/_summary',
    purchaseOrderFilter: baseApi + '/api/purchase-orders/_filter',
    suppliers: baseApi + '/api/suppliers',
    stores: baseApi + '/api/stores',
    changeStore: baseApi + '/api/user/change-store',
    stockIns: baseApi + '/api/stock-ins',
    marketplaces: baseApi + '/api/marketplaces',
    categoryPriceAdjustments: baseApi + '/api/categories/price-adjustment',
    allCategoryPriceAdjustments: baseApi + '/api/categories/price-adjustment/_all',
    stockSummaryPriceAdjustments: baseApi + '/api/stock-summaries/price-adjustment',
    allStockSummaryPriceAdjustments: baseApi + '/api/stock-summaries/price-adjustment/_all',
    stockSummaryErablueSalePrice: baseApi + '/api/stock-summaries/erablue-sale-price',
    dashboard: baseApi + '/api/dashboard',
    pullLatestFromOlsera: baseApi + '/api/schedulers/pull-latest-from-olsera',
    storeTransactions: baseApi + '/api/store-transactions',
    storeTransactionCategories: baseApi + '/api/store-transactions/_categories',
    paymentMethods: baseApi + '/api/payment-methods'
  },
  // pasang ac?
  trello: {
    jobs: baseApi + '/api/trellos/jobs',
    downloadJobReport: baseApi + '/api/trellos/jobs/_download'
  },
  // others
  thirdParty: {
    ipify: 'https://api.ipify.org?format=json'
  }
}

export { app, pages, api }
