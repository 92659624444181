const DEFAULT_SORT_FILTER = (key, onlySorter = false) => {
  const sorter = (a, b) => a[key] - b[key] || ('' + a[key]).localeCompare(b[key])

  if (onlySorter) {
    return { sorter }
  }

  return {
    sorter,
    filters: [],
    filterMode: 'menu',
    filterSearch: true,
    filterMultiple: true,
    onFilter: (value, record) => record[key].indexOf(value) === 0
  }
}

export default {
  table: {
    stockIn: {
      columns: [
        {
          title: 'Toko',
          dataIndex: 'storeCode',
          key: 'storeCode',
          ...DEFAULT_SORT_FILTER('storeCode')
        },
        {
          title: 'No. Transaksi',
          dataIndex: 'transactionNo',
          key: 'transactionNo'
        },
        {
          title: 'Tanggal',
          dataIndex: 'date',
          key: 'date',
          ...DEFAULT_SORT_FILTER('date', true)
        },
        {
          title: 'Produk',
          dataIndex: 'product',
          key: 'product',
          ...DEFAULT_SORT_FILTER('product')
        },
        {
          title: 'Kategori',
          dataIndex: 'category',
          key: 'category'
        },
        {
          title: 'Varian',
          dataIndex: 'variant',
          key: 'variant',
          ...DEFAULT_SORT_FILTER('variant')
        },
        {
          title: 'Supplier',
          dataIndex: 'supplier',
          key: 'supplier',
          ...DEFAULT_SORT_FILTER('supplier')
        },
        {
          title: 'Harga Beli',
          dataIndex: 'buyPrice',
          key: 'buyPrice'
        },
        {
          title: 'Rata-rata Harga Beli',
          dataIndex: 'avgBuyPrice',
          key: 'avgBuyPrice'
        },
        {
          title: 'Subtotal',
          dataIndex: 'subtotal',
          key: 'subtotal'
        },
        {
          title: 'Qty',
          dataIndex: 'qty',
          key: 'qty',
          ...DEFAULT_SORT_FILTER('qty', true)
        }
      ]
    },
    category: {
      columns: [
        {
          title: 'Gambar kategori',
          dataIndex: 'parentCategoryImageUrl',
          key: 'parentCategoryImageUrl'
        },
        {
          title: 'Nama kategori',
          dataIndex: 'olseraCategoryName',
          key: 'olseraCategoryName',
          ...DEFAULT_SORT_FILTER('olseraCategoryName')
        },
        {
          title: 'Prioritas',
          dataIndex: 'priority',
          key: 'priority',
          ...DEFAULT_SORT_FILTER('priority')
        },
        {
          title: 'Identifier',
          dataIndex: 'identifier',
          key: 'identifier'
        },
        {
          title: 'Tambahan biaya',
          dataIndex: 'additionalFee',
          key: 'additionalFee',
          ...DEFAULT_SORT_FILTER('additionalFee')
        },
        {
          title: 'Persen penyesuaian harga',
          dataIndex: 'increaseProductPricePercentage',
          key: 'increaseProductPricePercentage',
          ...DEFAULT_SORT_FILTER('increaseProductPricePercentage')
        }
      ]
    },
    stockSummary: [
      {
        title: 'Gambar',
        dataIndex: 'images',
        key: 'images',
        width: 250
      },
      {
        title: 'Informasi',
        dataIndex: 'info',
        key: 'info',
        width: 125
      },
      {
        title: 'Harga Erablue',
        dataIndex: 'erablueSalePrice',
        key: 'erablueSalePrice',
        width: 250
      },
      {
        title: '🟢 Ekstra Tokopedia Default',
        dataIndex: 'tokopediaPriceIncreaseAdditionalFee',
        key: 'tokopediaPriceIncreaseAdditionalFee',
        width: 250
      },
      {
        title: '🟢 Persen Tokopedia Default',
        dataIndex: 'tokopediaPriceIncreasePercentage',
        key: 'tokopediaPriceIncreasePercentage',
        width: 250
      },
      {
        title: '🟢 Ekstra Tokopedia Cikarang',
        dataIndex: 'tokopediaCikarangPriceIncreaseAdditionalFee',
        key: 'tokopediaCikarangPriceIncreaseAdditionalFee',
        width: 250
      },
      {
        title: '🟢 Persen Tokopedia Cikarang',
        dataIndex: 'tokopediaCikarangPriceIncreasePercentage',
        key: 'tokopediaCikarangPriceIncreasePercentage',
        width: 250
      },
      {
        title: '🟢 Ekstra Tokopedia Karawang',
        dataIndex: 'tokopediaKarawangPriceIncreaseAdditionalFee',
        key: 'tokopediaKarawangPriceIncreaseAdditionalFee',
        width: 250
      },
      {
        title: '🟢 Persen Tokopedia Karawang',
        dataIndex: 'tokopediaKarawangPriceIncreasePercentage',
        key: 'tokopediaKarawangPriceIncreasePercentage',
        width: 250
      },
      {
        title: '🟢 Ekstra Tokopedia Purwakarta',
        dataIndex: 'tokopediaPurwakartaPriceIncreaseAdditionalFee',
        key: 'tokopediaPurwakartaPriceIncreaseAdditionalFee',
        width: 250
      },
      {
        title: '🟢 Persen Tokopedia Purwakarta',
        dataIndex: 'tokopediaPurwakartaPriceIncreasePercentage',
        key: 'tokopediaPurwakartaPriceIncreasePercentage',
        width: 250
      },
      {
        title: '🟠 Ekstra Shopee',
        dataIndex: 'shopeePriceIncreaseAdditionalFee',
        key: 'shopeePriceIncreaseAdditionalFee',
        width: 250
      },
      {
        title: '🟠 Persen Shopee',
        dataIndex: 'shopeePriceIncreasePercentage',
        key: 'shopeePriceIncreasePercentage',
        width: 250
      },
      {
        title: '⚫ Ekstra Tiktok',
        dataIndex: 'tiktokPriceIncreaseAdditionalFee',
        key: 'tiktokPriceIncreaseAdditionalFee',
        width: 250
      },
      {
        title: '⚫ Persen Tiktok',
        dataIndex: 'tiktokPriceIncreasePercentage',
        key: 'tiktokPriceIncreasePercentage',
        width: 250
      },
      {
        title: 'Varian utama',
        dataIndex: 'primaryVariant',
        key: 'primaryVariant',
        align: 'center',
        width: 100
      },
      {
        title: 'Absolute',
        dataIndex: 'absolute',
        key: 'absolute',
        align: 'center',
        width: 100
      },
      {
        title: 'Published',
        dataIndex: 'published',
        key: 'published',
        align: 'center',
        width: 100
      },
      {
        title: 'Dibuat',
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: 150
      }
    ],
    storeTransaction: {
      columns: [
        {
          title: 'No. Olsera',
          dataIndex: 'transactionNo',
          key: 'transactionNo',
          ...DEFAULT_SORT_FILTER('transactionNo')
        },
        {
          title: 'Kategory',
          dataIndex: 'category',
          key: 'category',
          ...DEFAULT_SORT_FILTER('category')
        },
        {
          title: 'No. Nota / Nama',
          dataIndex: 'name',
          key: 'name',
          ...DEFAULT_SORT_FILTER('name')
        },
        {
          title: 'Nominal',
          dataIndex: 'amount',
          key: 'amount',
          ...DEFAULT_SORT_FILTER('amount')
        },
        {
          title: 'Cara Pembayaran',
          children: [
            {
              title: 'Pembayaran ke-1',
              dataIndex: 'paymentAmount',
              key: 'paymentAmount'
            },
            {
              title: 'Pembayaran ke-2',
              dataIndex: 'paymentAmount2',
              key: 'paymentAmount2'
            },
            {
              title: 'Pembayaran ke-3',
              dataIndex: 'paymentAmount3',
              key: 'paymentAmount3'
            },
            {
              title: 'Pembayaran ke-4',
              dataIndex: 'paymentAmount4',
              key: 'paymentAmount4'
            }
          ]
        },
        {
          title: 'Keterangan',
          dataIndex: 'notes',
          key: 'notes',
          ...DEFAULT_SORT_FILTER('notes')
        },
        {
          title: 'Aksi',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right'
        }
      ]
    }
  }
}
